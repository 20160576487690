import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { Noop } from '../../../extViewmodels/Utils';
import { bs } from '../../styles/styles';
import { LoadingSpinner } from '../LoadingSpinner';
import { styleSheet } from './styles';

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	accessory?: JSX.Element;
	className?: string;
	kind?: 'primary' | 'secondary' | 'success' | 'destructive' | 'reverse' | 'icon' | 'custom';
	label: string | JSX.Element;
	onClick?(e: React.MouseEvent<HTMLElement>): void;
	size?: 'normal' | 'small';
	styles?: StyleDeclarationValue[];
	isLoading?: boolean;
}

export const Button: React.FC<IProps> = ({
	accessory,
	label,
	kind: type = 'primary',
	size = 'normal',
	onClick,
	className,
	styles,
	disabled,
	isLoading,
	...otherProps
}) => {
	let style = '';
	switch (type) {
		case 'primary':
			if (size === 'normal') {
				style = css(bs.ctaButton);
			} else if (size === 'small') {
				style = css(bs.ctaButtonSmall);
			}
			break;

		case 'secondary':
			if (size === 'normal') {
				style = css(bs.ctaButtonSecondary);
			}
			break;

		case 'success':
			if (size === 'normal') {
				style = css(bs.ctaButton, styleSheet.success);
			} else if (size === 'small') {
				style = css(bs.ctaButtonSmall, styleSheet.success);
			}
			break;

		case 'destructive':
			if (size === 'normal') {
				style = css(bs.ctaButtonDestructive);
			} else if (size === 'small') {
				style = css(bs.ctaButtonReverseSmall);
			}
			break;

		case 'reverse':
			if (size === 'normal') {
				style = css(bs.ctaButtonReverse);
			} else if (size === 'small') {
				style = css(bs.ctaButtonReverseSmall);
			}
			break;
		case 'icon':
			style = css(styleSheet.ctaWithIcon);
			break;
		default:
			break;
	}

	return (
		<button
			className={`${style} ${disabled && css(bs.disabled)} ${css(...(styles || []))} ${className || ''}`}
			onClick={disabled || isLoading ? Noop : onClick}
			{...otherProps}
		>
			{isLoading ? (
				<LoadingSpinner type='tiny' />
			) : (
				<span>
					{type === 'icon' && accessory}
					{label}
				</span>
			)}
		</button>
	);
};
